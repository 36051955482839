import React, {useState} from 'react';
import './App.css';
import Player from "../src/Palyer";
function App() {
  const [players,setPlayer]=useState({
    player1:"",
    player2:"",
    player3:"",
    player4:"",
    player5:"",
    player6:"",
    player7:"",
    player8:"",
    player9:"",
    player10:"",
    player11:"",
    player12:"",
    player13:"",
  })
  const handleOnChange=(event) => {
    const {name,value}=event.target;
    setPlayer({...players,[name]:value});
  }
  return (
    <div className="App">
      <div className="wrapper">
        <table border="1" className="App-table">
          <thead>
            <tr>
              <th width="30px">SR</th>
              <th width="100px">Player</th>
              <th>Team 1</th>
              <th>Team 2</th>
              <th>Team 3</th>
              <th>Team 4</th>
              <th>Team 5</th>
              <th>Team 6</th>
              <th>Team 7</th>
              <th>Team 8</th>
              <th>Team 9</th>
              <th>Team 10</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td><input className="player" type="text" value={players.player1} name="player1" onChange={handleOnChange} placeholder="Enter player name" /></td>
              <td>{players.player1}</td>
              <td>{players.player1}</td>
              <td>{players.player1}</td>
              <td>{players.player1}</td>
              <td>{players.player1}</td>
              <td>{players.player1}</td>
              <td>{players.player2}</td>
              <td>{players.player2}</td>
              <td>{players.player2}</td>
              <td>{players.player3}</td>
            </tr>
            <tr>
              <td>2</td>
              <td><input className="player" type="text" value={players.player2} name="player2" onChange={handleOnChange} placeholder="Enter player name" /></td>
              <td>{players.player2}</td>
              <td>{players.player2}</td>
              <td>{players.player2}</td>
              <td>{players.player3}</td>
              <td>{players.player3}</td>
              <td>{players.player4}</td>
              <td>{players.player3}</td>
              <td>{players.player3}</td>
              <td>{players.player4}</td>
              <td>{players.player4}</td>
            </tr>
            <tr>
              <td>3</td>
              <td><input className="player" type="text" value={players.player3} name="player3" onChange={handleOnChange} placeholder="Enter player name" /></td>
              <td>{players.player3}</td>
              <td>{players.player4}</td>
              <td>{players.player5}</td>
              <td>{players.player4}</td>
              <td>{players.player5}</td>
              <td>{players.player5}</td>
              <td>{players.player4}</td>
              <td>{players.player5}</td>
              <td>{players.player5}</td>
              <td>{players.player5}</td>
            </tr>
            <tr>
              <td>4</td>
              <td><input className="player" type="text" value={players.player4} name="player4" onChange={handleOnChange} placeholder="Enter player name" /></td>
            </tr>
            <tr>
              <td>5</td>
              <td><input className="player" type="text" value={players.player5} name="player5" onChange={handleOnChange} placeholder="Enter player name" /></td>
            </tr>
            {
              [6,7,8,9,10,11,12,13].map((no)=>{
                return <Player key={no} no={no} name={`player${no}`} players={players} handleOnChange={handleOnChange} />
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default App;
