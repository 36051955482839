import React from "react";

const Player = (props) =>{
    const {players,handleOnChange,name,no}=props;
    return <tr>
        <td>{no-2}</td>
        <td><input className="player" type="text" value={players[name]} name={name} onChange={handleOnChange} placeholder="Enter player name" /></td>
        <td>{players[name]}</td>
        <td>{players[name]}</td>
        <td>{players[name]}</td>
        <td>{players[name]}</td>
        <td>{players[name]}</td>
        <td>{players[name]}</td>
        <td>{players[name]}</td>
        <td>{players[name]}</td>
        <td>{players[name]}</td>
        <td>{players[name]}</td>
  </tr>
}

export default Player;